body,
ul {
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
  margin: 0;
}

body {
  font-family: Arial, sans-serif;
  color: #333;
  background-color: rgb(39, 39, 39);
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: rgb(0, 0, 0);
  background-image: url('./images/unknowncreed_1.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.App-footer,
main {
  background-color: rgb(39, 39, 39);
  text-align: center;
  padding: 20px;
  border: 1px solid #ffd000;
}

.header {
  background-color: rgb(39, 39, 39);
  color: #ffd000;
  text-align: center;
  box-sizing: border-box;
  height: 40px;
}

.header h1 {
  margin: 0;
  margin-top: -15px;
  font-family: 'Charm', cursive;
}

main {
  background-color: transparent;
  border: none;
}

.App-footer {
  color: #ffd000;
  padding: 10px;
  border: none;
  position: fixed;
  bottom: 0;
  left: 0;
  border-top: 1px solid #ffd000;
  width: 100%;
  box-sizing: border-box;
}

nav ul {
  list-style-type: none;
}

nav li {
  display: inline;
  margin: 0 10px;
}

nav a {
  color: #ffd000;
  text-decoration: none;
  padding: 10px;
  border-radius: 5px;
  transition: color 0.3s ease-in-out;
}

nav a:hover {
  color: #ffd700;
}
