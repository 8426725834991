body,
ul {
  margin: 0;
  padding: 0;
}

body {
  font-family: Arial, sans-serif;
  background-color: #f2f2f2;
  color: #333;
}

.login-form,
.admin-panel {
  background-color: rgb(39, 39, 39);
  border: 1px solid #ffd000;
  border-radius: 5px;
  padding: 20px;
  margin: 0 auto;
}

.login-form {
  width: 300px;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: #ffd000;
}

.admin-panel {
  width: 60vw;
  margin-top: 20px;
  border: none;
  background: black;
}

.admin-section {
  min-height: 100vh;
}

.login-form input,
.login-form button,
.admin-panel button,
input,
select {
  padding: 10px;
  margin-bottom: 20px;
  font-size: 1em;
}

.login-form button,
.admin-panel button,
li .edit-button,
li button {
  background-color: #ffd000;
  color: black;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.login-form button:hover,
.admin-panel button:hover,
li .edit-button:hover,
li button:hover {
  background-color: #0056b3;
}

.raiders-section,
.squares-section,
.add-buttons-container,
.admin-panel ul,
.admin-panel li,
.button-container {
  margin-bottom: 20px;
}

h2 {
  color: #ffd000;
  border-bottom: 1px solid #ffd000;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.admin-panel ul {
  list-style-type: none;
}

.admin-panel li {
  padding: 15px;
  border: 1px solid #ffd000;
  background-color: #fafafa;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button-container,
.add-buttons-container {
  display: flex;
  gap: 10px;
}

li span {
  flex-grow: 2;
}

.login-error {
  color: red;
  text-align: center;
  margin-top: 10px;
  font-size: 0.9em;
}

.raider-buttons {
  display: flex;
  flex-flow: wrap row;
  margin-bottom: 20px;
  justify-content: center;
}

.raider-buttons button {
  margin-right: 1px;
  transition: background-color 0.3s ease-in-out;
}

.raider-buttons button.selected-raider {
  background-color: #4caf50;
  color: white;
}

.add-buttons-container {
  display: flex;
  justify-content: space-between;
}

.add-raider,
.add-square {
  display: flex;
  width: 48%;
}

.add-raider input,
.add-square input,
.add-raider button,
.add-square button {
  width: 50%;
  margin: 0;
}

.raider-name-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

li.list-item {
  background-color: #ccbe7e;
  color: black;
}
