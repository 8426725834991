.popup-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  background-color: #fff;
  z-index: 1000; /* Make sure this z-index is high enough to be above everything else */
  text-align: center;
  border: 2px outset #000;
  box-shadow: 2px 2px 0 #000;
  user-select: none;
}

.popup-title-bar {
  background-color: #000080; /* Dark blue title bar */
  color: white;
  padding: 5px 10px;
  font-family: 'Tahoma', sans-serif;
  font-size: 12px;
  user-select: none;
}

.popup-inner {
  padding: 20px;
  background-color: #c0c0c0;
  border: 1px solid #000;
  animation: popup-blinker 1s linear infinite;
  user-select: none;
}

@keyframes popup-blinker {
  50% {
    background-color: yellow;
  }
}

.popup-h2 {
  color: red;
  text-shadow: 1px 1px #000;
  margin-top: 10px;
  user-select: none;
}

.popup-p {
  font-family: 'Tahoma', sans-serif;
  font-size: 12px;
  color: #000;
  user-select: none;
}

.popup-button {
  background-color: blue;
  color: white;
  border: 1px outset blue;
  padding: 5px 15px;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  margin-top: 15px;
  border-radius: 3px;
  box-shadow: 1px 1px 0px #000;
}

.popup-button:hover {
  background-color: red;
  border-color: red;
}

.popup-title-bar {
  background-color: #000080; /* Dark blue title bar */
  color: white;
  padding: 5px 10px;
  font-family: 'Tahoma', sans-serif;
  font-size: 12px;
  position: relative; /* To position the close button */
}

.popup-close-button {
  position: absolute;
  top: 2px;
  right: 5px;
  background-color: #c0c0c0; /* Classic grey background */
  color: black;
  border: 1px outset #808080; /* Bezeled effect */
  font-weight: bold;
  cursor: pointer;
  padding: 0 5px;
  font-size: 12px;
  line-height: 1.5; /* Adjust the line height to center the 'X' vertically */
  border-radius: 2px; /* Slightly rounded corners */
}

.popup-close-button:hover {
  background-color: #d3d3d3; /* Lighten on hover */
  border: 1px inset #808080; /* Inset effect on hover */
}
