.bingo-card {
  font-family: 'Arial', sans-serif;
  width: 50%;
  margin: 0 auto;
  margin-top: -40px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  overflow-x: auto;
  background-color: black;
  color: #ffd000;
  overflow-y: hidden;
  max-width: 900px;
}

.bingo-card h1,
.raider-selection {
  text-align: center;
  margin-bottom: 20px;
}

.bingo-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
}

.bingo-item {
  position: relative;
  background-color: rgb(75, 75, 75);
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
  user-select: none;
  overflow: hidden;
}

.bingo-item p,
.bingo-item small,
.error-tooltip {
  text-align: center;
}

.bingo-item p {
  margin: 0;
  font-size: 16px;
  font-family: 'Bellefair', serif;
}

.bingo-item small {
  font-size: 12px;
  margin-top: 5px;
  font-family: 'Charm', cursive;
}

.bingo-item.clicked::after,
.bingo-item.free-space::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('./images/UC_ICON.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.5;
  pointer-events: none;
}

.bingo-item.clicked::after {
  animation: fadeInGrow 0.5s ease-in-out;
}

.bingo-item.swipe-out::after {
  animation: swipeOut 0.5s ease-in-out forwards;
}

.bingo-item.free-space p {
  font-weight: bold;
}

.instruction-text {
  color: #ffd000;
  text-align: center;
  margin-bottom: 20px;
}

.raider-button,
.generate-card-button,
.select-all-button,
.new-card-button {
  background-color: #ebdb95;
  border: 1px solid #ccc;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.raider-button:hover,
.generate-card-button:hover,
.select-all-button:hover,
.new-card-button:hover {
  background-color: #e0e0e0;
}

.raider-button.selected {
  background-color: #00ff62;
  border-color: #a0a0a0;
}

.error-tooltip {
  background-color: #ff3333;
  color: white;
  padding: 8px 15px;
  border-radius: 4px;
  position: absolute;
  top: 100%;
  right: 0;
  white-space: nowrap;
  z-index: 10;
  margin-top: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  font-size: 0.9rem;
}

.error-tooltip::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 10px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ff3333 transparent;
}

.generate-card-wrapper {
  position: relative;
  display: inline-block;
}

.bingo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  padding: 20px;
}

.new-card-button {
  background-color: #ffd000;
  color: black;
}

.loading {
  color: #ffd000;
  font-size: 20px;
  animation: loadingAnimation 2s linear infinite;
}

@keyframes fadeInGrow {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 0.5;
    transform: scale(1);
  }
}

@keyframes swipeOut {
  from {
    opacity: 0.5;
    transform: translate(0, 0);
  }
  to {
    opacity: 0;
    transform: translate(-100%, 100%);
  }
}

@keyframes loadingAnimation {
  0% {
    opacity: 0.1;
    transform: scale(0.95);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
  100% {
    opacity: 0.1;
    transform: scale(0.95);
  }
}

.bingo-item.special::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('./images/grippers.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0;
  pointer-events: none;
  animation: slideDown 0.5s ease-in-out forwards;
}

.bingo-item.special.swipe-out::after {
  animation: slideUp 0.5s ease-in-out forwards;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 0.5;
    transform: translateY(0);
  }
}

@keyframes slideUp {
  from {
    opacity: 0.5;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-100%);
  }
}
.cascade-effect::before,
.cascade-effect::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url('./images/grippers.png');
  background-size: contain;
  background-repeat: repeat;
  pointer-events: none;
  opacity: 0;
  z-index: 10;
}

.cascade-effect::before {
  animation: cascadeFromTopAnimation 2s ease-in-out forwards;
  top: -100%;
  left: 0;
}

.cascade-effect::after {
  animation: cascadeFromTopAnimation 2s ease-in-out forwards;
  top: -100%;
  left: 0;
  animation-delay: 1s;
}

@keyframes cascadeFromTopAnimation {
  0% {
    opacity: 0;
    transform: translateY(0);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(100%);
  }
}
